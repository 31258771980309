<template>
  <div id="nav">
    <router-link class-active="active" to="/">Home</router-link>
    <router-link class-active="active" to="/resources">Resources</router-link>
    <router-link class-active="active" to="/materials">Materials</router-link>
    <router-link class-active="active" to="/production">Production</router-link>
  </div>
</template>

<script>
export default {
  name: "PageHeader"
};
</script>

<style scoped>
#nav {
  padding: 10px;
  border: 10px;
  background-color: #1a1a1a;
  margin-bottom: 20px;
}
 nav li:hover,
 nav li.router-link-active,
 nav li.router-link-exact-active {
   background-color: indianred;
 }

 a {
  background-color: black;
  border-color: silver;
  border-radius: 4px;
  color: white;
  padding: 5px;
  text-decoration: none;
}

a:hover{
  border-bottom: 2px solid lightblue;
}

.active, a.router-link-active {
  background-color: gray;
}
</style>