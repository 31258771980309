import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/resources',
    name: 'ResourceList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Resources.vue')
  },  
  {
    path: '/inventory',
    name: 'InventoryList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inventory.vue')
  },  
  {
    path: '/bases',
    name: 'BasesList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Bases.vue')
  },  
  {
    path: '/materials',
    name: 'MaterialtList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Materials.vue')
  },
  {
    path: '/components',
    name: 'ComponentList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Components.vue')
  },
  {
    path: '/production',
    name: 'ProductionPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Production.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
