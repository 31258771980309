<template>
  <div class="home">
    <h2>Farsite Tools</h2>
  </div>
</template>

<script>
// @ is an alias to /src
//import Matches from "@/components/Matches.vue";
//import MatchComponent from "@/components/MatchComponent.vue";

export default {
  name: "ViewHome",
  components: {
    //MatchComponent
  },
  data() {
    return {
      matches: [{ id: 1, summoner: "Lessu" }, { id: 2, summoner: "Lessu2" }]
    };
  },
  methods: {

  },
  created() {
    //this.matches = [...this.matches, { id: 3, summoner: "abba" }];
  }
};
</script>
