<template>
  <div id="app">
    <PageHeader />    
    <router-view v-slot="{Component}">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import PageHeader from "./components/layout/PageHeader";
export default {
  name: 'App',
  components: { PageHeader }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;

  background-color: black;

  overflow: hidden;
  width: 100vw;
}
body{
  background-color: black;
}
.fade-enter-from, .fade-leave-to{
  opacity:0;
  transform: translateX(2em);
}
.fade-enter-active, .fade-leave-active{
  transition: all .3s ease;
}
</style>
